.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:white; /* white background */
  padding: 2px 10px;
  box-shadow: 0.3px 2px 3px rgba(1, 9, 16,100);
  margin: 0px 0px 4px 0px;

 
}

.logo-container {
  display: flex;
  align-items: center;
  flex: 1; /* Take up remaining space */
}

.logo {
  width: 80%; /* Full width */
  max-width: 200px; /* Set maximum width as needed */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;

}

a{
  color: #ffff;
}
.auth-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.auth-button {
  padding: 8px 15px;
  border: none;
  background-color:#07285e ;
  color: white; /* light color */
  font-size: 13px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.2); /* double shadow effect */
}

.auth-button:hover {
  background-color: rgb(48, 93, 142); /* white color */
  transform: translateY(-2px); /* lift the button slightly on hover */
}
.Toastify{
  z-index: 999999999999;
}

@media(max-width: 700px) {
  .auth-button {
    padding: 7px 14px;
    border: none;

    font-size: 11px;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.2); /* double shadow effect */
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:white; /* white background */
    padding: 0 5px;
    box-shadow: 0.3px 2px 3px rgba(1, 9, 16,100);
    margin: 0px 0px 4px 0px;
  
   
  }
  
}

