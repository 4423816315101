.signup-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color:#f0f0f0; /* White background */
  position: fixed; /* Change to fixed positioning */
  left: 50%;
  top: 50%;
  width: 50%;
  transform: translate(-50%, -50%) scale(0); /* Initial scale is 0 */
  z-index: 99999;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: zoomIn 0.5s forwards; /* Apply zoom-in animation */
  
}
  @keyframes zoomIn {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
  }
  .signup-form {
    width: 90%; /* Increased width */
    padding: 30px; /* Increased padding */
    border-radius: 8px;
 
    text-align: center;
    
  }

  
  .signup-form .input-div {
    display: flex;
    flex-wrap:wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
   
  }
  

  
  .signup-input {
    width: 40%;
    margin-bottom: 10px;
    padding: 10px;
    gap: 15px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    resize: vertical; /* Allow vertical resizing */
    overflow: auto; /* Enable scrolling if needed */
 
  }

  /* Remove default styles for select dropdown */
  .input-div select {
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow for Safari */
    -moz-appearance: none; /* Remove default arrow for Firefox */
    background-color: #fff; /* Set background to transparent */
    width: 45%;
     height: 40px;
    cursor: pointer; /* Change cursor to pointer */
    
  }


  
  
  label{
    padding: 10px 2px;
    font-size: 12px;
  }
  .term-button {
    background: none;
    border: none;
    color:#092d59; /* Blue color */
    cursor: pointer;
  }
  .form-options {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
  }

  
  .signup-button {
  
    padding: 10px;
    background-color: #07285e ; /* Blue background */
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
  }
  
  .signup-button:hover {
    background-color: rgb(48, 93, 142); 
  }
  
  .signup-options {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    
  }
  .signup-container span{
    font-size: 12px;
    color: red;
  }
  p {
    margin-top: 10px;
    font-size: 14px;
  }
  
 
  
  a:hover {
    text-decoration: underline;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #888; /* Adjust color as needed */
  }
  
  .close-button:hover {
    color: #555; /* Adjust hover color */
  }

  @media(max-width:973px){
    .signup-container {
      flex-direction: column;
    width: 90%;
      }
      .signup-form .input-div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
      }
      .signup-input span{
        font-size: 9px;
        color: red;
      }
      ::placeholder{
        font-size: 12px;
      }
      }


      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
      }