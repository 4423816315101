.banner {
  position: relative;
  color: white;
  padding: 100px 0;
  background-size: cover;
  background-position: center;
  background: linear-gradient(to top, rgba(0, 0, 50, 0.85) 0%, rgba(0, 0, 0, 0.8) 100%);
  transition: background-image 0.5s ease, opacity 0.5s ease; /* Smooth transition for background image and opacity */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Add shadow effect */
  animation: fadeInOut 6s infinite alternate; /* Apply animation */
  height: 300px; /* Adjust the height as needed */
}


.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity of the overlay */
}

.banner img {
  opacity: 0.5; /* Opacity of the banner images */
}
.background-switcher {
  position: relative; /* Ensure relative positioning for animation */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure icons are above the background */
}

.background-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%; /* Circular shape */
  background-color: #a38d8d; /* Background color of the icons */
  margin-left: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.background-icon.active {
  background-color:#fff; /* Change color when active */
}



.auth-active .landing-page {
  opacity: 0.3; /* Reduce opacity when authentication component is active */
  
}



.container {
  width: 100%;
  margin: 0 auto;
}

.banner-wrap {
  text-align: center;
}

.banner-title {
  position: relative; /* Ensure relative positioning for animation */
  font-size: 1.8rem;
  margin-bottom: 10px; /* Reduced margin for better spacing */
  opacity: 1; /* Increase the visibility */
  display: block; /* Display as block-level element */
  padding: 20px 10px;
  font-family: "Poppins", sans-serif;
  

}

.banner-title span {
  color:#07285e; /* Change the color of the 'to' text */
}

.banner-subtitle {
  font-size: 1.2rem;
  line-height: 1.5;
  display: block;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif;
  position: relative; /* Ensure relative positioning for animation */

}










.aerodrome-selection {
  background-color: #f0f0f0; /* Light gray background */
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.underline{
  height: 5px;
  width: 200px;
  background-color: #07285e;


}
/* .aerodrome-selection:hover .underline{
  background-color: red;
} */

.aerodrome-selection .container {
  width: 80%;
  margin: 0 auto;

}

.aerodrome-selection h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Dark gray text color */
}

.aerodrome-selection .options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  
}

.aerodrome-selection .option {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1.2rem;
  background-color: #007bff; /* Blue background color */
  color: white; /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aerodrome-selection .option:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.card {
  background: linear-gradient(#ffffff 95%, #002E5D 5%);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 40px;
  padding: 50px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  position: relative; /* Ensure proper positioning for overlay */
  overflow: hidden; /* Hide overflowing gradient */
  cursor: pointer;
 
}

.card::before {
  content: '';
  position: absolute;
  top: 100%; /* Start from bottom */
  left: 0;
  width: 100%;
  height: 100%;
  background: #002E5D;
  transition: top 0.5s; /* Smooth transition for top position */
  
}

.card:hover::before {
  top: 0%; /* Fill up to the top on hover */
}


.card:hover .thumbnail img,
.card:hover .content {
  color: #fff; /* Change the color of thumbnail and content on hover */
}
.icon-container button{
  border-style: none;
  background-color: transparent;
  cursor: pointer;

}
.icon-container{
  position:absolute;
  top: 30px;
  left:10px;
  z-index: 999;
  transition: opacity 0.3s ease, color 0.3s ease 0.2s; /* Smooth transition for opacity with 1 second delay for color */
}
.icon-container i{
  font-size: 30px;

  
}
/* .card:hover img{
z-index: -999;
opacity: 0.1;

} */


.card:hover .icon-container button{
  color: #fff;
}

.card:hover .buttons .link-button {
  padding: 8px 16px;
  background: #fff;
  color: #120808; /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.buttons {
  display: flex;
  justify-content: center; /* Center align the buttons */
  margin-top: 10px;
}

.buttons .link-button {
  padding: 8px 16px;
  background:#07285e ;
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  
}

.buttons .link-button:hover {
  color: #0056b3; /* Darker blue on hover */
}
.card:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.description {
  z-index: 1; /* Ensure description is above overlay */
}

.buttons {
  z-index: 1; /* Ensure buttons are above overlay */
}
/* Thumbnail.module.css */
.thumbnail {
  width: 100%; /* Set the desired width */
  height: 300px; /* Set the desired height */

  z-index: 1;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
  object-position: center; /* Center the image within the container */
}



.content{
  z-index: 1;
}


.description {
  margin-bottom: 20px;
}

.buttons button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttons .view-btn {
  background-color: #55b2b0;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* shadow effect */
}

.buttons .view-btn:hover {
  background-color: #b1caca;
}

.buttons .share-btn {
  background-color: #55b2b0;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* shadow effect */
}

.buttons .share-btn:hover {
  background-color: #b1caca;
}

.etod-info {
  padding: 50px 0;
  background-color: #f0f0f0; /* Light gray background */
}

.description {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description p {
  max-width: 70%; /* Adjust the maximum width as needed */
  overflow-wrap: break-word; /* This allows the text to wrap within its container */
}

.etod-info .container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
}

.etod-info h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Dark gray text color */
  font-family: "Poppins", sans-serif;
}
.etod-info p{
  font-family: "Poppins", sans-serif;
}


.etod-info .answer {
  font-size: 1.2rem;
  line-height: 1.5;
}

iframe {
  width: 560px;
  height: 315px;
}
.card-manual-section{
  padding: 50px 0;
}
.card-manual-section h2{
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Dark gray text color */
}
.card-manual-div {
  margin-top: 40px;
  margin-left: 80px;
  margin-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
flex-wrap: wrap;
  gap: 10px;
}

.card-manual-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e8e6e6;
  
}

.card-manual-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-manual {
  background: linear-gradient(to left, #079597  3%, #ffffff 3%, #ffffff 97%, #079597 97%);
  
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px; /* Initial width */
  height: 100px; /* Initial height */
  position: relative; /* Ensure proper positioning for overlay */
  overflow: hidden; /* Hide overflowing gradient */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

.card-manual:hover {
  transform: scale(1.05); /* Scale up the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add a larger shadow on hover */
}

.card-manual h3 {
  margin-bottom: 10px;
}

.card-manual button {
  background: #07285e;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 10px;
  transition: background-color 0.3s ease; /* Adding transition for smooth hover effect */
}

.card-manual button:hover {
  background: #275092; /* Change background color on hover */
}

.Quick-button .link-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #07285e;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 35px;
  margin-bottom: 45px;
  margin-right: 5px;
  position: relative; /* Ensure relative positioning for animation */
  overflow: hidden; /* Hide overflow to prevent animation overflow */
}

.Quick-button .link-button:hover {
  background-color: #4c6690;
}

.buttons .link-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #07285e;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-right: 5px;
  position: relative; /* Ensure relative positioning for animation */
  overflow: hidden; /* Hide overflow to prevent animation overflow */
}







.link-button:focus {
  outline: none;
}
 a{
  text-decoration: none;
}
.datasets-count {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}
.meet-tag {
  display: flex;
  margin-bottom: 10px;
  position: absolute;
  top: 10px;
  
  
}

.meet-tag i {
  margin-right: 4px;
  color: #00cc00; /* Green color for the check icon */
}

.meet-tag span {
  font-size: 14px;
  color: #00cc00; /* Green color for the check icon */
}
.card:hover .datasets-count   {
 color: #fff;
}

/* Adjust the aspect ratio for different video sizes */
@media(max-width:990px){
  .banner {
    height: 350px;
  }
  .card-manual h3 {
 font-size: 14px;
}
.card-manual {
  
  height: 60px; /* Adjust height as needed */
  width: 300px;

}

.description p {
  max-width: 60%; /* Adjust the maximum width as needed */
  overflow-wrap: break-word; /* This allows the text to wrap within its container */
}
.icon-container i{
  font-size: 20px;
}
}
.additional-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background:#e8e6e6;
  
}

.contact-info {
  flex: 1;
  padding-right: 20px; /* Adjust spacing between contact info and map */
}



@media screen and (max-width: 768px) {
  .banner-title {
    font-size: 1rem;
    margin-bottom: 0px; /* Reduced margin for better spacing */
    opacity: 1; /* Increase the visibility */
    display: block; /* Display as block-level element */
    padding: 20px 10px;
  }
  .meet-tag span {
    font-size: 11px;
    color: #00cc00; /* Green color for the check icon */
  }
  .banner {
    padding: 70px 0;
  }
  .banner-subtitle {
    font-size: 0.7rem;
    line-height: 1.5;
    display: block; /* Display as block-level element */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow effect */
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .buttons button {
    padding: 7px 14px;
    margin: 0 3px;
    font-size: 0.7rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }


  .card {
    background-color: #fff; /* White background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow effect */
    margin: 20px;
    padding: 34px;
    text-align: center;
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
  }
  .card-manual button {
    background: #07285e;
    border: none;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 10px;
    transition: background-color 0.3s ease; /* Adding transition for smooth hover effect */
  }
  .content{
    margin-top: 30px;
  }
  iframe {
    height: 215px;
    width: 300px;
  }

  .etod-info h2 {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #333; /* Dark gray text color */
  }

  .etod-info .answer {
    font-size: 1rem;
    line-height: 1.5;
  }
  
.description p {
  max-width: 50%; /* Adjust the maximum width as needed */
  overflow-wrap: break-word; /* This allows the text to wrap within its container */
}
.icon-container i{
  font-size: 20px;
}
.thumbnail {

  height: 200px; /* Set the desired height */

  
}

}

.options img {
  border-radius: 20px;
}



.link-button:focus {
  outline: none;
}
