.reset-password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    background-color:#f0f0f0; /* White background */
    margin: 17% 50% 0% 50%;
    transform: translate(-50%, -50%) scale(0); /* Initial scale is 0 */

    border-radius: 30px;
    width: 40%; /* Increased width */
    padding: 30px; /* Increased padding */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 200px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: zoomIn 0.5s forwards; /* Apply zoom-in animation */
  }


  
  .send-password-reset-email .input-div{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .reset-password-input{
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #cccccc; /* Light gray border */
    border-radius: 8px;
  }

  .reset-password-button{
    padding: 10px;
    background-color:#07285e ; /* Blue background */
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  @media(max-width:800px){
    .reset-password{
        height:350px;
        width: 70%;
    } 
  }