/* About.css */

.about-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
  }
  
  .about-container h2 {
    color: #333;
  }
  
  .information,
  .terrain-data,
  .obstacle-data,
  .tin-image {
    margin-bottom: 20px;
  }
  
  .information h3,
  .terrain-data h3,
  .obstacle-data h3 {
    color: #007bff;
  }
  
  .terrain-data ul,
  .obstacle-data ul {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .terrain-data strong,
  .obstacle-data strong {
    font-weight: bold;
  }
  
  .terrain-data p,
  .obstacle-data p {
    margin-bottom: 10px;
  }
  
  .tin-img, .fly-img{
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  