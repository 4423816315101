*{
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  overflow: hidden;
}


h1, h2, h3, h4, h5, h6,p, button, span, label, input, textarea, select,a{
  font-family: "Poppins", sans-serif;
}


