.info_IGI{
    position: absolute;
    z-index: 99;
    width: 400px;
    height: 300px;
    top: 50px;
    left: 8%;
    background-color: #f0f0f0;
    border-radius: 6px;
    padding: 3px;
    overflow-y: auto;
}

.info_IGI p {
    font-size: 13px;
    }
    @media(max-width:636px){
        .info_IGI{
            width: 300px;
            height: 250px;
        }
        .info_IGI p{
            font-size: 11px;
        }
    }
    
    @media(max-width:495px){
        .info_IGI {
            width: 250px;
            height: 240px;
        }
    }
    
    @media(max-width:430px){
        .info_IGI {
            width: 220px;
            height: 240px;
        }
    }
    
    @media(max-width:370px){
        .info_IGI {
            width: 200px;
            height: 240px;
        }
    }