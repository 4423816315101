.dashboard {
  height: 87vh;
  width: 100vw;
  background-color: #55b2b0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
}

.layer-panel {
  height: 94%;
  width: 24%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1); /* Enhanced box shadow */
  padding: 20px;
  overflow: hidden;

  
}

.layer-panel .panel-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.layer-panel .layers {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.aerodrome {
  margin-bottom: 20px;
}

.aerodrome h5 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.select-box {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.select-box:focus {
  border-color: #55b2b0;
}

.layers {
  margin-bottom: 20px;
}

.layers h5 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.layer-panel .select-box.clicked {
  border-color: #55b2b0;
}
.layer-group-content{
  display: flex;
  flex-direction: column;
  gap:10px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 5px 0px;
  width: 100%;

}

.sub-layer-group-header{
  margin-top: 10px;
  margin-left:10px;
  display:flex;
  gap: 10px;
}
.sub-layer-group-header:hover{
  color: blue;
}
.sub-layer-group-header span{
  font-size: 12px;
  max-width: 95%;
  overflow-wrap: break-word;
  cursor: pointer;
  

}
.sub-layer-group{
  width: 100%;
  
}
.sub-layer-content {
  padding: 15px 0px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.layer-panel .scrollable-panel {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  width: 108%;
  height: 450px;
}


.layer-panel .layer-option {
  display: flex;
  flex-direction: column;
  align-items: center;

  
  gap: 10px;
  font-size: 14px;
  color: #333;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
 
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}
.layer-panel .layer-option  .layer-toggler{
  display: flex;
  width: 100%;
  gap: 5px;
}
.layer-group{
   margin-left:35px;
   margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.layer-combo{
  width: 100%;
}
.layer-panel .layer-text {
  max-width: 80%;
  overflow-wrap: break-word;
}

/* .layer-panel .layer-option input[type="checkbox"] {
  display: none;
} */

.layer-panel .layer-option .radio-custom {
  width: 20px;
  height: 20px;
  border: 3px solid #483838;
  border-radius: 50%;
  position: relative;
}

.layer-panel .layer-option input[type="checkbox"]:checked + .radio-custom::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background-color: #0a1514;
  border-radius: 50%;
}

.map-panel {
  height: 100%;
  width: 90%;
  margin-right:5px;
  background-color: #fff;
 position: relative;
}


.layer-panel .select-box.clicked {
  border: 2px solid #1f05a1;
}
.lat-lon {
  position: absolute;
  margin-top: 20px;
  top: 8px;
  left: 8px;
  z-index: 99999;
}

.search-button {
background-color: #ffff; /* Blue background */
  border: 1px solid black;
  border-radius: 50%; /* Circle shape */
  color:#000; /* White icon color */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Circle dimensions */
  height: 40px;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.search-button:hover {
  background-color: #7bafe5; /* Active button background color */
}

.search-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0px 0px 0px 3px rgba(0, 123, 255, 0.5); /* Custom focus outline */
}

.search-button i {
  font-size: 20px; /* Adjust icon size */
}


.search-form {
  position: absolute;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  z-index: 9999;
  right: 35%;
  top: 0px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  transition: box-shadow 0.3s ease;
}

.search-form:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.search-form input {
  width: 60px;
  margin-right: 10px;
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-form input:focus {
  border-color: #55b2b0;
}

.search-form button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #55b2b0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.search-form button:hover {
  background-color: #448f8d;
}

.toggle-button button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  position: absolute;
  right: 78%;
  top: 100px;
  z-index: 9999;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.layer-group-header{
  cursor: pointer;
  display: flex;

  align-items: center;
  gap: 10px;
 
}
.layer-group-header:hover{
  color: blue;
}
.zoom-to-layer-btn {
  background-color: #007bff; /* Blue background color */
  color: white; /* White text color */
  border: none; /* No border */
  padding: 5px 10px; /* Padding */
  font-size: 12px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor style */
  transition: background-color 0.3s; /* Smooth background color transition on hover */
}

.zoom-to-layer-btn:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

/* Adjust the size and color of the Font Awesome icon */
.zoom-to-layer-btn .fa-search-plus {
  margin-right: 5px; /* Add some space between icon and text */
  font-size: 18px; /* Icon size */
}

.baselayer_toggle {
  position: absolute;
  display: flex;
  gap: 2px;
  right: 80px;
  top: 10px;
  z-index: 999;
}

.baselayer_toggle button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Add a smooth transition effect */

}


.baselayer_toggle img {
  width: 48px;
  height: 48px;
  margin-right: 5px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4)); /* Add a shadow effect */
  border-radius: 5px;
}

.baselayer_toggle img:hover{
  border: 1px solid black;
 
}
.baselayer_toggle button.active img {
  border: 3px solid rgb(11, 175, 31);

}
.mouse-cursor {
  position: fixed;
  z-index: 99999999;
  top: 180px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mouse-cursor button {
  background: none; /* Removes default background */
  border: none; /* Removes default border */
  padding: 0; /* Removes default padding */
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.mouse-cursor-toggler {
  position: absolute;
  z-index: 99999999;
  left: 10px;
  top: 50%;
  

 
}
.hover-coordinates {
  position: absolute;
  z-index: 99999999;
  left: 10px;
  bottom: 1%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
  font-size: 14px;
  width: auto;
}
.lat-lng{
  display: flex;

}


.mouse-cursor-toggler button{
  background: none; /* Removes default background */
  border: none; /* Removes default border */
  padding: 0; /* Removes default padding */
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
 
  align-items: center;
}

.custom-button-icon-toggler{
  height: 40px; /* Adjust button height */
  width: 40px; /* Adjust button width */
  display: flex;
  justify-content: center;
  align-items: center;

}
.custom-button-icon-toggler img{
  height: 30px;
  width: 30px;
  color: white;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.custom-button-icon {
  height: 40px; /* Adjust button height */
  width: 40px; /* Adjust button width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button-icon img {
  height: 20px;
  width: 20px;
  color: white;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}
.custom-button-icon img:hover{
  background-color: #7bafe5; /* Active button background color */
  color: rgb(255, 255, 255); /* Active button text color */
}

 .info-active img,
 .pan-active img
 {
 border: 3px solid red;
  color: rgb(255, 255, 255); /* Active button text color */
}




@media (max-width: 700px) {
  .dashboard {
    height: 86vh;
    width: 99vw;
    background-color: #55b2b0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .layer-panel {
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  .map-panel {
    height: 60%;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
 
  .aerodrome {
    margin-bottom: 5px;
    width: 90%;
  }
  .aerodrome h5 {
    font-size: 13px;
    color: #333;
  }
  .layers h5 {
    font-size: 13px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }

  .search-button {
  
    width: 30px; /* Circle dimensions */
    height: 30px;
  
  }
  
  .search-form {
    position: absolute;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    z-index: 999;
    width: 200px;
    left: 5px;
    top: 60px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: box-shadow 0.3s ease;
  }
  .search-form input {
    margin-right: 5px;
    width: 50px;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 8px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  .search-form button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #55b2b0;
    color: #fff;
    font-size: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
  }
  .toggle-button button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    position: absolute;
    right: 90%;
    top: 60px;
    z-index: 9999;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
  }

  
  .baselayer_toggle button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: opacity 0.3s ease; /* Add a smooth transition effect */

  }

  
  .baselayer_toggle img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4)); /* Add a shadow effect */
    border-radius: 5px;
  }
  
  .baselayer_toggle img:hover{
    border: 0.5px solid black;
    width: 44px;
    height: 44px;
  }
  .baselayer_toggle button.active img {
    border: 0.5px solid black;
    width: 44px;
    height: 44px;
  }
  .mouse-cursor {
    position: fixed;
    z-index: 99999999;
    right: 20px;
    top: 175px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .mouse-cursor button {
    background: none; /* Removes default background */
    border: none; /* Removes default border */
    padding: 0; /* Removes default padding */
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
   
    align-items: center;
  }
  
.custom-button-icon {
  height: 25px; /* Adjust button height */
  width: 25px; /* Adjust button width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button-icon img {
  height: 20px;
  width: 20px;
  color: white;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px 10px;
  margin-bottom: 10px;

  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.custom-button-icon img:hover{
  background-color: #7bafe5; /* Active button background color */
  color: rgb(255, 255, 255); /* Active button text color */
}

 .info-active img,
 .pan-active img
 {
 border: 3px solid red;
  color: rgb(255, 255, 255); /* Active button text color */
}
.mouse-cursor-toggler{
  display: none;
}
}

.maplibregl-popup-close-button{
  font-size: 30px;
}
.metadata {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
  padding: 15px;
  margin-left: 25px;
 
}

.metadata strong {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
}

.metadata-content {
  text-align: left;
}

.metadata-item {
  padding: 5px 0;
}

.metadata-item strong {
  font-weight: bold;
}


/* styles.css */
.popup-content {
  font-family: Arial, sans-serif;
  padding: 5px;
  max-width: 200px;
}
.popup-content p{
  font-size: 12px;
}
.popup-header {
  background-color: #f8f9fa;
  border-bottom: 2px solid #007bff;
  padding: 4px;
  border-radius: 2px 2px 0 0;
}

.popup-header h2 {
  margin: 0;
  color: #007bff;
  font-size: 13px;
}

.popup-body {
  padding: 10px;
}

.popup-body h3 {
  margin-top: 0;
 
  
}

.popup-footer {
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  height: 250px;
  overflow-y: scroll;
}

.popup-footer p {
  margin: 0; /* Remove default margin for paragraphs */
  text-align: left; /* Ensure paragraphs are left-aligned */
}


.layer-panel.collapsed {
   display: none;
}
.map-panel.expanded {
  width: 100%; /* Example width when collapsed */
  position: relative;
}

