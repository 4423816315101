.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e8e6e6; /* White background for the footer */
  color: #333; /* Text color */
  text-align: center; /* Aligning the content to the center */
}

.footer-text {
  margin-top: 10px; /* Add margin above the footer text */

  

}